import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import {greeting} from "../../portfolio";
import {Fade} from "react-reveal";
import {messages} from "../../localization";
import quttingTime from "../../assets/images/quttingTime.svg";

export default function Greeting() {
  let JAVA_LEARN_YEAR = 2016;
  let PYTHON_LEARN_YEAR = 2018;
  let REACT_LEARN_YEAR = 2020;
  let YEAR_OF_TODAY = new Date().getFullYear();

  let split = messages.greeting.text.getLocale()
      .replace("%1%",YEAR_OF_TODAY - JAVA_LEARN_YEAR)
      .replace("%2%",YEAR_OF_TODAY - PYTHON_LEARN_YEAR)
      .replace("%3%",YEAR_OF_TODAY - REACT_LEARN_YEAR)
      .split("FCT-UNESP");

  return (
    <Fade bottom duration={1000} distance="40px">
    <div className="greet-main" id="greeting">
      <div className="greeting-main">
        <div className="greeting-text-div">
          <div>
            <h1 className="greeting-text">
              {" "}
              {messages.greeting.title.getLocale()}
              {" "}
            </h1>
            <span className="wave-skeleton">
              {/*<img style={{width: 200, height: 200}} src={require("../../assets/images/skeleton.svg")}>*/}
              {/*  */}
              {/*</img>*/}
              {/*<SkeletonIcon/>*/}
            </span>

            <p className="greeting-text-p subTitle">
              {split[0] /*Before Link*/}
              <a className="greeting-text-p subTitle" href="https://www.fct.unesp.br/">FCT-UNESP</a>
              {split[1] /*After Link*/}
            </p>
            <SocialMedia />
            <div className="button-greeting-div">
              <Button text={messages.greeting.button_contact.getLocale()} href="#contact" />
              <Button text={messages.greeting.button_resume.getLocale()} newTab={true} href={greeting.resumeLink} />
            </div>
          </div>
        </div>
        <div className="greeting-image-div">
          <img alt="sitting on table" src={quttingTime}></img>
        </div>
      </div>
    </div>
    </Fade>
  );
}
