import React from "react"
import "./Services.css";
import ServiceCard from "../../components/serviceCard/ServiceCard"
import { servicesSection } from "../../portfolio"
import { Fade } from "react-reveal"
import {messages} from "../../localization";

export default function Services() {
    if (servicesSection.services) {
        return (
            <div id="services" style={{ paddingTop: "20px" }}>
                <Fade top duration={1000} distance="20px">
                    <div className="services-container" id="services">
                        <div style={{ width: "100%" }}>
                            <h1 className="services-heading">{messages.services.title.getLocale()}</h1>
                            <div className="services-main-div">
                                {
                                    servicesSection.services.map((service, index) => {
                                            let the_service = {
                                                title: messages.services[service.name + "_title"].getLocale(),
                                                description: messages.services[service.name + "_desc"].getLocale(),
                                                image: service.image,
                                            };
                                            return (
                                                <ServiceCard
                                                    cardInfo={the_service}
                                                    className={`service-default`}
                                                    key={index}
                                                />
                                            )
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        )
    }
    return null
}