import emoji from "react-easy-emoji";

if (sessionStorage['locale'] == null){
  let userLanguage = window.navigator.userLanguage || window.navigator.language;
  if (userLanguage != null && userLanguage.includes("pt") || userLanguage.includes("br")){
    sessionStorage['locale'] = 'br'
  }else {
    sessionStorage['locale'] = 'en'
  }
}

const defaultLocale = sessionStorage['locale']; // English is default locale if none is set

const localeList = [
  { name: 'English', code: 'en', lang: 'English' },
  { name: 'Português', code: 'br', lang: 'Portuguese' }
];

class LocaleMessage {
  constructor(key:string) {
    this.key = key;
    this.locale = new Map();
  }
  addLocale(localeCode:string, localeValue:string){
    this.locale.set(localeCode, localeValue);
  }
  getLocale() {
    return this.locale.get(defaultLocale);
  }
}

const messages = {

  header: {

    username: {
      en: "EverNife",
      br: "EverNife"
    },

    me: {
      en: "Me",
      br: "Eu"
    },

    skills: {
      en: "Skills",
      br: "Habilidades"
    },

    projects: {
      en: "Projects",
      br: "Projetos"
    },

    services: {
      en: "Services",
      br: "Serviços"
    },

    contact: {
      en: "Contact Me",
      br: "Contato"
    },

  },

  greeting:{

    title: {
      en: "Hi, I'm Pétrus",
      br: "Ola, eu sou Pétrus"
    },

    text: {
      en: `A Brazilian Computer Scientist graduated at FCT-UNESP that loves programming. Having more than %1% years of experience with Java Applications, %2% years of Python Data Science and %3% years of React/Next.js Front End.`,
      br: `Cientista da Computação formado na FCT-UNESP que adora programar. Tendo mais de %1% anos de experiência em Aplicações Java, %2% anos em Python Data Science e %3% anos em React/Next.js Front End.`,
    },

    button_contact: {
      en: "CONTACT ME",
      br: "ENTRAR EM CONTATO"
    },

    button_resume: {
      en: "SEE MY RESUME",
      br: "Curriculum vitæ"
    },

  },

  skills:{

    title: {
      en: "Skills",
      br: "Proficiência"
    },

    skill_1: {
      en: "✔ High Performant Java MultiThread Applications.",
      br: "✔ Aplicações Java MultiThread em Alta Performance."
    },

    skill_2: {
      en: "✔ Experienced in Java Reverse Engineering.",
      br: "✔ Experiência em Engenharia Reversa - Java."
    },

    skill_3: {
      en: "✔ FullStack Web Developer.",
      br: "✔ Desenvolvedor Web FullStack."
    },

    skill_4: {
      en: "✔ Data Analytics with Python.",
      br: "✔ Data Analytics em Python."
    },

    skill_5: {
      en: "✔ Minecraft Spigot/Sponge/Forge Developer!",
      br: "✔ Desenvolvedor Minecraft Spigot/Sponge/Forge!"
    },

  },

  services:{

    title: {
      en: "Services",
      br: "Serviços"
    },

    java_title: {
      en: "Java Applications",
      br: "Aplicações Java"
    },

    java_desc: {
      en: "High Performance Java Applications for Cloud or Desktop.",
      br: "Aplicações Java de Alta Performance para Cloud ou Desktop."
    },

    minecraft_title: {
      en: "MineCraft",
      br: "MineCraft"
    },

    minecraft_desc: {
      en: "Full optmized Forge mods and Bukkit/Sponge Plugins for servers on any minecraft version.",
      br: "Forge Mods e Bukkit/Sponge Plugins completamente otimizados para servidores em qualquer versão do minecraft."
    },

    python_title: {
      en: "Python Analytics",
      br: "Python Analytics"
    },

    python_desc: {
      en: "Python Descriptive, Predictive and Prescriptive Analytics.",
      br: "Analise Descritiva, Preditiva e Prescritiva utilizando Python."
    },

    fullstack_title: {
      en: "Full Stack Web Development",
      br: "Desenvolvedor Web Full Stack"
    },

    fullstack_desc: {
      en: "Full Stack Web Developer, React front-end and Java Spring Boot back-end.",
      br: "Desenvolvedor Full Stack, front-end com React e back-end com Java Spring Boot."
    },

  },


  contact:{

    title: {
      en: "Contact Me",
      br: "Entrar em Contato"
    },

    desc: {
      en: "WANT TO CONTACT ME? USE THE EMAIL OR MAYBE MY DISCORD!",
      br: "QUER ENTRAR EM CONTATO? USE MEU EMAIL OU ENTÃO MEU DISCORD!"
    },

  },

}

//Convert all to Class LocaleMessage
for (let category of Object.keys(messages)) {
  for (let keyName of Object.keys(messages[category])) {
    var localeMessage = new LocaleMessage(category  + "." + keyName);
    for (let localeCode of Object.keys(messages[category][keyName])) {
      localeMessage.addLocale(localeCode, messages[category][keyName][localeCode]);
    }
    messages[category][keyName] = localeMessage;
  }
}

function getMessage(message:LocaleMessage) {
  return message[defaultLocale];
}

export { defaultLocale, localeList, messages, getMessage};