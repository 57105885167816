import React from "react"
import Proptypes from "prop-types"
import "./ServiceCard.css"


function ServiceCard({ cardInfo, ...divProps }) {
    divProps.className = "servicecard-main-div " + divProps.className;
    return (
        <div className={divProps.className}>
            <div style={{ padding: "30px 15px 15px" }}>
                <img className="servicecard-image" src={cardInfo.image} alt="Service"/>
                <h3 >{cardInfo.title}</h3>
                <p className="card-subtitle">{cardInfo.description}</p>
            </div>
        </div>
    )
}

ServiceCard.propTypes = {
    cardInfo: Proptypes.object.isRequired,
}

export default ServiceCard
