import { SiTypescript } from "react-icons/si";
import {TbBrandFlutter} from "react-icons/tb";

import emoji from "react-easy-emoji";

const greeting = {
  /* Your Summary And Greeting Section */
  username: "EverNife",
  resumeLink: "www.ainda_nao_criei_um.com.br"
};

// Your Social Media Link
const socialMediaLinks = {
  github  : "https://github.com/evernife",
  linkedin: "https://www.linkedin.com/in/petrus-pradella-149215156/",
  outlook: "petrus_ant@hotmail.com",
  facebook: "https://www.facebook.com/petrus.pradella/",
  kagle: "https://www.kaggle.com/evernife",
  // Instagram and Twitter are also supported in the links!
};

// Your Skills Section
/* Make Sure You include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */
const skillsSection = {
  softwareSkills: [
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "React",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "Flutter",
      fontAwesomeClassname: "",
      reactIcon: <TbBrandFlutter/>
    },
    {
      skillName: "HTML-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "CSS3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "TypeScript",
      fontAwesomeClassname: undefined,
      reactIcon: <SiTypescript/>
    },
    {
      skillName: "PHP",
      fontAwesomeClassname: "fab fa-php"
    },
    {
      skillName: "SQL-DataBase",
      fontAwesomeClassname: "fas fa-database"
    },
  ],

  frameworks: [
    {
      skillName: "linux",
      fontAwesomeClassname: "fab fa-linux"
    },
    {
      skillName: "github",
      fontAwesomeClassname: "fab fa-github"
    },
    {
      skillName: "NodeJS",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN, //process.env.REACT_APP_GITHUB_TOKEN
  githubUserName: "evernife", // Change to your github username to view your profile in Contact Section.
  showGithubProfile :"false" // Set true or false to show Contact profile using Github, defaults to false
};

// Some Big Projects You have worked with your company
const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: "",//require("./assets/images/saayaHealthLogo.webp"),
      link: ""
    },
    {
      image: "",//require("./assets/images/nextuLogo.webp"),
      link: ""
    }
  ]
};

// Blogs Section

const blogSection = {

  title: "Blogs",
  subtitle: "Some usefull blog posts",

  blogs: [
    {
      url: "https://medium.com/@evernife/some-cool-thing-i-will-do",
      title: "",
      description: ""
    }
  ]
};

const contactInfo = {
  discord_name: "EverNife#2513",
  discord_userid: "176383963986657280",
  email_address: "petrus_ant@hotmail.com"
};


const minecraftSection = {
  image: require("./assets/images/gandalf.png"),
  title: "Minecraft Dev",
  subtitle: [
    "I am the Owner of a Modded Minecraft Network called FinalCraft",
  ]
}

const servicesSection = {
  title: "Services",
  subtitle: [

  ],
  services: [
    {
      name: "java",
      image: require("./assets/images/services/java.svg"),
    },
    {
      name: "minecraft",
      image: require("./assets/images/services/skeleton.ico"),
    },
    {
      name: "python",
      image: require("./assets/images/services/analytics.png"),
    },
    {
      name: "fullstack",
      image: require("./assets/images/services/designer.svg"),
    },
  ],
}

export { greeting, socialMediaLinks, skillsSection, openSource, bigProjects, blogSection, contactInfo , servicesSection, minecraftSection};
