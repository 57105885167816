import React from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skillsSection } from "../../portfolio";
import {Fade} from "react-reveal";
import {messages} from "../../localization";
import manCoding from "../../assets/images/manCoding.svg";

export default function Skills() {
  return (
      <div className="main" id="skills">
        <div className="skills-main-div">
          <Fade left duration={1000}>
            <div className="skills-image-div">
              <img alt="Man Coding" src={manCoding}></img>
            </div>
          </Fade>
          <Fade right duration={1000}>
            <div className="skills-text-div">
              <h1 className="skills-heading">{messages.skills.title.getLocale()} </h1>
              <SoftwareSkill />
              <div>
                {
                  Object.entries(messages.skills).map((entry, index) => {
                    if (entry[0].includes("skill_")){
                      return <p className="subTitle skills-text" key={index} >{entry[1].getLocale()}</p>;
                    }
                  })
                }
              </div>
            </div>
          </Fade>
        </div>
      </div>
  );
}
