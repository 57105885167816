import React from "react";
import Toggle from "react-toggle"
import Headroom from "react-headroom";
import {messages} from "../../localization";
import {greeting} from "../../portfolio";
import "./Toggle.css"
import "./Header.css";

import en_flag from "../../assets/images/localeflags/en.png"
import br_flag from "../../assets/images/localeflags/br.png"

const changeLocale = () => {
  sessionStorage['locale'] = sessionStorage['locale'] == "br" ? "en" : "br";
  console.log("Locale Change to: " + sessionStorage['locale'])
  window.location.reload();
}

function Header() {
  return (
      <Headroom>
        <header className="header sticky" >
          <a href="" className="logo">
            <span className="grey-color"> &lt;</span>
            <span className="logo-name-bigspace">{greeting.username}</span>
            <span className="grey-color">/&gt;</span>
          </a>
          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label>
          <ul className="menu">
            <li>
              <a href="#greeting">{messages.header.me.getLocale()}</a>
            </li>
            <li>
              <a href="#skills">{messages.header.skills.getLocale()}</a>
            </li>
            <li>
              <a href="#services">{messages.header.services.getLocale()}</a>
            </li>
            <li>
              <a href="#contact">{messages.header.contact.getLocale()}</a>
            </li>
            <span className="toggle">
              {
                <Toggle
                    defaultChecked={sessionStorage['locale'] == "br"}
                    icons={{
                      checked: (
                          <img
                              src={br_flag}
                              width="16"
                              height="16"
                              alt="br"
                              style={{
                                pointerEvents: "none",
                                width: "16px",
                                heigth: "16px",
                              }}
                          />
                      ),
                      unchecked: (
                          <img
                              src={en_flag}
                              width="16"
                              height="16"
                              alt="en"
                              style={{
                                pointerEvents: "none",
                                width: "16px",
                                heigth: "16px",
                              }}
                          />
                      ),
                    }}
                    aria-label="Change locale"
                    onChange={changeLocale}
                />
              }
            </span>
          </ul>

        </header>
      </Headroom>
  );
}
export default Header;
