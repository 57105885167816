import React from "react";
import "./Contact.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import {contactInfo} from "../../portfolio";
import { Fade } from "react-reveal";
import {messages} from "../../localization";
import freelancer from "../../assets/images/freelancer.svg";

const GetSubTitles = ({ subtitles }) => {
    return subtitles ? <p className="subTitle contact-subtitle">{subtitles}</p> : null
};

export default function Contact() {
    return (
        <Fade bottom duration={1000} distance="20px">
        <div className="main contact-margin-top" id="contact">
            <div className="contact-div-main">
                <div className="contact-header">
                    <h1 className="heading contact-title">{messages.contact.title.getLocale()} <i className="fas fa-file-signature"></i></h1>

                    <GetSubTitles subtitles={messages.contact.desc.getLocale()} />

                    <div className="contact-text-div">
                        <a className="contact-detail" target={'_blank'} href={"https://discordapp.com/users/" + contactInfo.discord_userid}><i className="fab fa-discord"></i>{contactInfo.discord_name}</a>
                        <br/><br/>
                        <a className="contact-detail-email"
                           href={"mailto:" + contactInfo.email_address}>{contactInfo.email_address}</a>
                        <br/><br/>
                        <SocialMedia/>
                    </div>
                </div>
                <div className="contact-image-div">
                    <img alt="Working" src={freelancer}></img>
                </div>
            </div>
        </div>
        </Fade>
    );
}
