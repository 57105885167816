import React from "react";
import "./SoftwareSkill.css";
import { skillsSection } from "../../portfolio";

export default function SoftwareSkill() {
    return (
        <div>
            <div className="software-skills-main-div">
                <ul className="dev-icons">
                    {skillsSection.softwareSkills.map( (skills, index) => {
                        return (
                            <li className="software-skill-inline" name={skills.skillName} key={index}>
                                {skills.fontAwesomeClassname && <i className={skills.fontAwesomeClassname}></i>}
                                {skills.reactIcon && <i> {skills.reactIcon} </i> }
                                <p>{skills.skillName}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="software-skills-main-div">
                <ul className="dev-icons">
                    {skillsSection.frameworks.map( (skills, index) => {
                        return (
                            <li className="software-skill-inline" name={skills.skillName} key={index}>
                                <i className={skills.fontAwesomeClassname}></i>
                                <p>{skills.skillName}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}
