import React, { Component } from "react";
import Header from "../components/header/Header";
import Greeting from "./greeting/Greeting";
import Skills from "./skills/Skills";
import Projects from "./projects/Projects";
import Services from "../containers/services/Services"
import Profile from "./profile/Profile";
import Footer from "../components/footer/Footer";
import Top from "./topbutton/Top";

export default class Main extends Component {
    render() {
        return (
            <div>
                <Header />
                <Greeting />
                <Skills />
                <Services />
                <Profile />
                <Footer />
                <Top />
            </div>
        );
    }
}
